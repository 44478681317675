<template>  
  <div class="transit">
      <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="less">
  *{
    padding: 0;
    margin: 0;
  }
</style>
