import { createRouter, createWebHashHistory } from "vue-router"

const home = () => import("../App.vue")
const login = () => import("../components/login.vue")
const transfer = () => import("../components/transfer.vue")
const comprehensive = () => import("../components/comprehensive.vue")
const AfterCode = () => import("../components/AfterCode.vue")
const routerHistory = createWebHashHistory()
const router = createRouter({
  history: routerHistory,
  routes: [
      {
          path: '/',
          component: home,
          children: [
              {
                  path: '/',
                  name:'AfterCode',
                  meta: {title:'述职评议擂台赛'},
                  component: AfterCode
              },
              {
                  path: '/logins',
                  name:'logins',
                  meta: {title:'述职评议擂台赛'},
                  component: login
              },
              {
                  path: '/transfer',
                  name:'transfer',
                  meta: {title:'综合科室组'},
                  component: transfer
              },
              {
                  path: '/comprehensive',
                  name:'comprehensive',
                  meta: {title:'年度第二场评议擂台赛'},
                  component: comprehensive
              },
          ]
      },
  ]
})

export default router